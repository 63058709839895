import React from 'react';
import PropTypes from 'prop-types';

import { Svg } from '../system';

const Funnel = ({ color = 'white', ...props }) => (
  <Svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 247.5 247.5">
    <path
      fill={color}
      d="M246.7 14a7.5 7.5 0 0 0-6.7-4.3H7.5A7.5 7.5 0 0 0 1.7 22l89.4 108.3v100a7.5 7.5 0 0 0 10.8 6.7l50.2-24.9a7.5 7.5 0 0 0 4.2-6.7v-75L245.8 22a7.5 7.5 0 0 0 1-8zM143.1 122.9a7.5 7.5 0 0 0-1.7 4.7l-.1 73.1-35.2 17.5v-90.6c0-1.7-.6-3.4-1.7-4.7l-81-98.2h200.7l-81 98.2z"
    />
  </Svg>
);

Funnel.propTypes = {
  color: PropTypes.string
};

export default Funnel;
