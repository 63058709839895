import React, { Component } from 'react';
import { Link } from '@reach/router';
import { withSiteData } from 'react-static';

import Container from './Container';
import Funnel from './svg/Funnel';
import Logo from './svg/Logo';
import NotificationBar from './NotificationBar';
import { Box, NavLink, Text } from './system';

import theme from '../styles/theme';

const notificationBarHeight = '2rem';

class Header extends Component {
  state = {
    menuExpanded: false,
    notificationVisible: true
  };

  toggleNotification = () => {
    const { notificationVisible } = this.state;
    this.setState({ notificationVisible: !notificationVisible });
  };

  toggleMenu = () => {
    const { menuExpanded } = this.state;
    this.setState({ menuExpanded: !menuExpanded });
  };

  render() {
    const { menuExpanded, notificationVisible } = this.state;
    const { categories, asFilter = false } = this.props;
    return (
      <Box
        as="header"
        bg={theme.colors.nearwhite}
        css={`radial-gradient(circle, ${theme.colors.white} 0%, ${theme.colors.nearwhite} 100%);`}
      >
        <NotificationBar
          bg="black"
          height={notificationBarHeight}
          isVisible={notificationVisible}
          toggleVisibility={this.toggleNotification}
        >
          <Text fontSize={1} fontWeight="bold" uppercase>
            Free Delivery
          </Text>
        </NotificationBar>
        <Box
          mt={notificationVisible ? notificationBarHeight : 0}
          css={`
            transition: margin ${theme.transitions.md} ease;
          `}
        >
          <Container py={[3, null, 4]} px={[3, null, 5]}>
            <Box maxWidth="25rem" mx="auto">
              <Box mr="2%">
                <Link aria-label="Supplement Deals Home" to="/">
                  <Logo />
                </Link>
                <Text as="p" mb={0} mt={-2} color="gray" fontSize={1} textAlign="right" pr={1}>
                  Powered by{' '}
                  <Text
                    as="a"
                    href="https://www.sportsfuel.co.nz"
                    target="_blank"
                    rel="noreferrer noopener"
                    color="black"
                    css={`
                      text-decoration: none;
                      &:hover {
                        text-decoration: underline;
                      }
                    `}
                  >
                    Sportsfuel
                  </Text>
                </Text>
              </Box>
            </Box>
          </Container>
          <Box bg="yellow">
            <Box
              as="nav"
              textAlign={['center']}
              bg={['yellow', null, 'xlightgray']}
              py={!menuExpanded ? [0, null, 1] : [3, null, 1]}
              maxHeight={!menuExpanded ? [0, null, 'none'] : ['22rem', null, 'none']}
              css={`
                transition: all ${theme.transitions.md};
                overflow: hidden;
              `}
            >
              {categories.map(category => (
                <NavLink
                  onClick={this.toggleMenu}
                  key={category.handle}
                  color="black"
                  label={`Go to ${category.title}`}
                  to={`/${category.handle}`}
                  fontSize={0}
                  fontWeight="bold"
                  px={2}
                  py={[2, null, 1]}
                  display={['table', null, 'inline-block']}
                  mx="auto"
                  uppercase
                >
                  {category.title}
                </NavLink>
              ))}
            </Box>
            <Box
              onClick={this.toggleMenu}
              as="button"
              display={['block', null, 'none']}
              width={1}
              bg={menuExpanded ? ['darkyellow', null, 'xlightgray'] : 'xlightgray'}
              border="none"
              py={2}
              css={`
                span,
                & {
                  transition: all ${theme.transitions.md};
                }
              `}
            >
              {asFilter ? (
                <Text uppercase fontWeight="bold">
                  {!menuExpanded ? 'Filter Products' : 'Hide Filter'}
                  <Box width="0.8rem" display="inline-block" ml={1}>
                    <Funnel color="black" />
                  </Box>
                </Text>
              ) : (
                <Text uppercase fontWeight="bold">
                  {!menuExpanded ? 'Product Categories' : 'Hide Categories'}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

const HeaderWithData = withSiteData(Header);

export default HeaderWithData;
