import React from 'react';
import PropTypes from 'prop-types';

import Close from './svg/Close';
import { Box } from './system';

import theme from '../styles/theme';

const NotificationBar = ({
  bg = 'black',
  color = 'white',
  children,
  height,
  isVisible,
  toggleVisibility,
  ...rest
}) => (
  <Box
    bg={bg}
    color={color}
    position="fixed"
    top={0}
    left={0}
    right={0}
    bottom={0}
    zIndex={10}
    height={height}
    display="flex"
    justifyContent="center"
    alignItems="center"
    opacity="0.9"
    css={{
      textTransform: 'uppercase',
      transition: `transform ${theme.transitions.md} ease`,
      transform: `translateY(${isVisible ? '0' : '-110%'})`
    }}
    {...rest}
  >
    {children}
    <Box
      as="button"
      bg="transparent"
      border="none"
      position="absolute"
      top={0}
      right={0}
      width={height}
      height={height}
      p="0.6rem"
      type="button"
      aria-label="Hide Notification Bar"
      css="cursor:pointer;"
      onClick={toggleVisibility}
    >
      <Close />
    </Box>
  </Box>
);

NotificationBar.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  toggleVisibility: PropTypes.func.isRequired
};

export default NotificationBar;
