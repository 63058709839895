import React from 'react';
import PropTypes from 'prop-types';

import { Svg } from '../system/Svg';

import theme from '../../styles/theme';

const Close = ({ color = theme.colors.white, ...props }) => (
  <Svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 357 357">
    <path
      fill={color}
      d="M357 35.7L321.3 0 178.5 142.8 35.7 0 0 35.7l142.8 142.8L0 321.3 35.7 357l142.8-142.8L321.3 357l35.7-35.7-142.8-142.8z"
    />
  </Svg>
);

Close.propTypes = {
  color: PropTypes.string
};

export default Close;
